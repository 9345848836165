<template>
    <v-container>
        <v-row>
            <v-col cols="6">
                <v-card flat>
                    <v-card-title>
                        <v-icon>mdi-calendar-clock</v-icon>
                        <span>Arrangement</span>
                    </v-card-title>
                    <v-card-text v-if="aktiviteter.length" class="text--primary">
                        <p>Kommende arrangement</p>
                        <activities-list :activities="aktiviteter"> </activities-list>
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Det er ingen kommende arrangementer.</p>
                    </v-card-text>
                </v-card>

                <!--<v-card flat v-if="myArchivedActivities.length">
                    <v-card-title>
                        <v-icon>mdi-calendar-check-outline</v-icon>
                        <span>Aktiviteter jeg har deltatt på</span>
                    </v-card-title>
                    <v-card-text v-if="myArchivedActivities && myArchivedActivities.length" class="text--primary">
                        <activities-list :activities="myArchivedActivities"> </activities-list>
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Du har ikke deltatt på noen aktiviteter enda.</p>
                    </v-card-text>
                </v-card>-->
            </v-col>
            <v-col cols="6">
                <v-card v-if="canEdit" flat to="/arrangementer">
                    <v-card-title>
                        <v-icon>mdi-calendar-edit</v-icon>
                        <span>Rediger arrangementer</span>
                    </v-card-title>
                    <v-card-text class="text--primary"> Opprett nye, rediger arrangment og se deltakere. </v-card-text>
                </v-card>

                <v-card flat v-if="mineAktiviteter && mineAktiviteter.length > 0">
                    <v-card-title>
                        <v-icon>mdi-calendar-check-outline</v-icon>
                        <span>Mine aktiviteter</span>
                    </v-card-title>
                    <v-card-text v-if="mineAktiviteter && mineAktiviteter.length" class="text--primary">
                        <activities-list :activities="mineAktiviteter"> </activities-list>
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Du er ikke påmeldt til noen arrangement.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import ActivitiesList from '@/components/ActivitiesList.vue';

export default {
    name: 'ActivitiesPage',
    components: {
        ActivitiesList,
    },
    data() {
        return {
            aktiviteter: [],
            mineAktiviteter: [],
        };
    },
    computed: {
        ...mapGetters('api', ['user', 'maalgrupper', 'hasRole']),
        canEdit: function () {
            return this.hasRole('ANSATT');
        },
    },
    created: async function () {
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity']),

        /**
         * update
         */
        update: async function () {
            const maalgrupper = this.maalgrupper;
            const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

            let response = await this.request({
                method: 'post',
                url: '/aktivitet/sok',
                data: {
                    fra: today.format('DD.MM.YYYY HH:mm'),
                    maalgrupper: maalgrupper,
                },
            });

            if (response && Array.isArray(response)) {
                const aktiviteter = [];
                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.type.id != 'SORGGRUPPE') {
                        aktiviteter.push(aktivitet);
                    }
                }

                this.aktiviteter = aktiviteter;
            }

            response = await this.request({
                method: 'get',
                url: '/person/' + this.user.id + '/aktiviteter',
            });

            if (response && Array.isArray(response)) {
                const mineAktiviteter = [];
                for (let aktivitet of response) {
                    aktivitet = await this.formatActivity(aktivitet);
                    if (aktivitet.type.id != 'SORGGRUPPE' && aktivitet.start > today) {
                        mineAktiviteter.push(aktivitet);
                    }
                }

                this.mineAktiviteter = mineAktiviteter;
            }
        },
    },
};
</script>
